<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h2 class="overline mt-2 ml-2 grey--text d-flex">Campaña 2021</h2>
          <h1 class="ml-2" style="font-weight: 200">Grouped Slice input</h1>
        </div>

        <div>
          <h3 class="ml-2 mb-2" style="font-weight: 200">
            Stored Cost Item Groups
          </h3>
        </div>
        <v-data-table
          dense
          :options="{ itemsPerPage: 15 }"
          :headers="
            ['Name', 'Elements'].map((i) => {
              return { text: i.replaceAll('_', ' '), value: i };
            })
          "
          :items="costItemGroups"
          @click:row="handleRowClick"
        ></v-data-table>

        <div class="d-flex mt-4">
          <v-text-field
            label="Nombre de Grupo"
            v-model="newCostGroup.name"
            dense
            class="mr-2"
          >
          </v-text-field>

          <v-btn
            @click="saveNewCostGroup()"
            color="accent white--text"
            class="ml-2"
            depressed
          >
            <v-icon> mdi-floppy </v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import { postRequest } from "@/managrx/serverCall";

export default {
  data() {
    return {
      newCostGroup: {
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters(["costItemGroups"]),
  },

  mounted() {
    this.$store.dispatch("fetchCostGroups");
  },
  methods: {
    handleRowClick(e) {
      this.$router.push({
        path: `/stored-cost-item-group/${e.id}`,
      });
    },
    async saveNewCostGroup() {
      let response = await postRequest(
        "/stored_cost_item_group",
        this.newCostGroup
      );

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response)
      }else{

      this.newCostGroup = {
        name: "",
      };
      this.$store.dispatch("fetchCostGroups");
    }
    },
  },
};
</script>
